export class DictionaryRequest {
  #api;

  constructor(requestPerformer) {
    this.#api = requestPerformer;
  }

  async getCountryList() {
    try {
      return await this.#api.get("/dictionary/country");
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getConditionList() {
    try {
      return await this.#api.get("/dictionary/condition");
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getDuplicateBehaviourList() {
    try {
      return await this.#api.get("/dictionary/duplicateBehaviour");
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getDuplicateCheckLevelList() {
    try {
      return await this.#api.get("/dictionary/duplicateCheckLevel");
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getTagList() {
    try {
      return await this.#api.get("/dictionary/tag");
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getBrandList() {
    try {
      return await this.#api.get("/dictionary/brand");
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async getMarkerList() {
    try {
      return await this.#api.get("/dictionary/marker");
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
